import { Alert, Space } from "antd"
import {
  getLocalStorageItem,
  LocalStorageItemName,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "src/common/utils/local-storage"
import { Button } from "src/ui/Button/Button"
import { makeVar, useMutation, useReactiveVar } from "@apollo/client"
import { graphql } from "src/api/graphql"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"
import { ErrorBoundary } from "react-error-boundary"
import { ReactNode } from "react"
import { Link } from "src/ui/typography/Link/Link"

interface CommonAlertProps {
  message: string
  description: string | ReactNode
  buttonText: string
  onButtonClick: () => void
}

export const itemKey: LocalStorageItemName = "enableAgentRequestPending"

export const isEnableAgentRequestPendingVar = makeVar(Boolean(getLocalStorageItem(itemKey)))

export function RequestAgentAlert() {
  const isPending = useReactiveVar(isEnableAgentRequestPendingVar)
  const actionAlert = isPending ? <CancelReqAlert /> : <CreateReqAlert />

  return <ErrorBoundary FallbackComponent={ErrorAlert}>{actionAlert}</ErrorBoundary>
}

export const CreateEnableAgentRequestDocument = graphql(`
  mutation createEnableAgentRequest {
    createEnableAgentRequest {
      success
    }
  }
`)

export const CancelEnableAgentRequestDocument = graphql(`
  mutation cancelEnableAgentRequest {
    cancelEnableAgentRequest {
      success
    }
  }
`)

function CommonAlert(props: CommonAlertProps) {
  const { message, description, buttonText, onButtonClick } = props

  return (
    <Alert
      showIcon
      message={message}
      description={
        <Space direction="vertical">
          {description}
          <Button size="small" onClick={() => onButtonClick()}>
            {buttonText}
          </Button>
        </Space>
      }
    />
  )
}

function CreateReqAlert() {
  const [createEnableAgentRequest] = useMutation(CreateEnableAgentRequestDocument)
  const handleClick = async () => {
    await createEnableAgentRequest()
    isEnableAgentRequestPendingVar(true)
    setLocalStorageItem(itemKey, "true")
  }
  return (
    <CommonAlert
      message="Want to connect to data in your own environment?"
      description="By default, GX Cloud connects directly to your data through a cloud-to-cloud connection. If you want to manage this connection in your own environment instead, use the button below to get the GX Agent enabled for your organization."
      onButtonClick={handleClick}
      buttonText="Request Agent"
    />
  )
}

function CancelReqAlert() {
  const [cancelEnableAgentRequest] = useMutation(CancelEnableAgentRequestDocument)
  const handleClick = async () => {
    await cancelEnableAgentRequest()
    isEnableAgentRequestPendingVar(false)
    removeLocalStorageItem(itemKey)
  }
  return (
    <CommonAlert
      message="Pending GX Agent enablement"
      description={
        <div>
          {
            "We received your request to enable the GX Agent and will send you a confirmation email upon completion. In the meantime, "
          }
          <Link to="https://docs.greatexpectations.io/docs/cloud/deploy_gx_agent/" target="_blank">
            deploy the GX Agent
          </Link>
          .
          <br />
        </div>
      }
      onButtonClick={handleClick}
      buttonText="Cancel request"
    />
  )
}

function ErrorAlert() {
  return (
    <Alert
      showIcon
      message="Unexpected Error"
      description={
        <>
          {"To request the GX Agent, please email "}
          <SupportEmailLink />
        </>
      }
      type="error"
    />
  )
}
