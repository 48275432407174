import { EMAIL_ALERT_DISABLED_DESCRIPTION, EMAIL_ALERT_ENABLED_DESCRIPTION } from "src/Alerts/words"
import { useEffect, useState } from "react"
import { Icon } from "src/ui/Icon"
import { Switch } from "antd"
import { AlertCard } from "src/ui/Card/Card"
import { graphql } from "src/api/graphql"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import { Error as DrawerError } from "src/Alerts/AlertsDrawer"
import { BodyStrong } from "src/ui/typography/Text/Text"
import styled from "styled-components"

const StyledAlertCard = styled(AlertCard)`
  border: none;
`

interface EmailAlertCardProps {
  assetId: string
  onEmailAlertError: (error: DrawerError) => void
}

interface EmailDescriptionProps {
  isSwitchChecked: boolean
  assetName: string | undefined
}

export const USER_EMAIL_ALERT_SUBSCRIPTION_TITLE = "Subscribe"

const EmailDescription = ({ isSwitchChecked, assetName }: EmailDescriptionProps) => {
  const nameBackup = "this Data Asset"
  return (
    <div>
      {isSwitchChecked ? (
        <>
          {EMAIL_ALERT_ENABLED_DESCRIPTION[0]} {assetName ? <BodyStrong>{assetName}</BodyStrong> : <>{nameBackup}</>}{" "}
          {EMAIL_ALERT_ENABLED_DESCRIPTION[1]}
        </>
      ) : (
        <>
          {EMAIL_ALERT_DISABLED_DESCRIPTION[0]} {assetName ? <BodyStrong>{assetName}</BodyStrong> : <>{nameBackup} </>}{" "}
          {EMAIL_ALERT_DISABLED_DESCRIPTION[1]}
        </>
      )}
    </div>
  )
}

const GetDataAssetDocument = graphql(`
  query GetDataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      name
      emailAlertEnabled
    }
  }
`)

const CreateEmailAlertV2Document = graphql(`
  mutation CreateEmailAlert($input: CreateEmailAlertInput!) {
    createEmailAlertV2(input: $input) {
      # This is a partial Asset object that can only return an ID and an emailAlertEnabled field.
      asset {
        id
        emailAlertEnabled
      }
    }
  }
`)

const UpdateEmailAlertDocument = graphql(`
  mutation UpdateEmailAlert($input: UpdateEmailAlertInput!) {
    updateEmailAlertV2(input: $input) {
      # This is a partial Asset object that can only return an ID and an emailAlertEnabled field.
      asset {
        id
        emailAlertEnabled
      }
    }
  }
`)

export const EmailAlertCard = ({ assetId, onEmailAlertError }: EmailAlertCardProps) => {
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean | null | undefined>(null)
  const {
    data,
    loading,
    error: getDataAssetError,
  } = useQuery(GetDataAssetDocument, {
    variables: {
      id: assetId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setIsSwitchChecked(data.dataAsset?.emailAlertEnabled)
    },
  })
  useEffect(() => {
    if ((!loading && data?.dataAsset?.emailAlertEnabled === undefined) || getDataAssetError) {
      onEmailAlertError({
        title: "An error occurred",
        description: getDataAssetError?.message || "Error getting email alert",
      })
    }
  }, [data?.dataAsset?.emailAlertEnabled, getDataAssetError, loading, onEmailAlertError])

  const handleMutationError = (e: ApolloError) => {
    onEmailAlertError({ title: "Error updating email alert", description: e.message })
    setIsSwitchChecked((preVal) => !preVal)
  }

  const [createAlert] = useMutation(CreateEmailAlertV2Document, {
    onError: handleMutationError,
  })

  const [updateAlert] = useMutation(UpdateEmailAlertDocument, {
    onError: handleMutationError,
  })

  const updateOrCreateEmailAlert = (isChecked: boolean) => {
    setIsSwitchChecked(isChecked)
    if (data && data?.dataAsset?.emailAlertEnabled === null) {
      createAlert({
        variables: {
          input: {
            dataAssetId: assetId,
          },
        },
      })
    } else {
      updateAlert({
        variables: {
          input: {
            dataAssetId: assetId,
            emailAlertEnabled: isChecked,
          },
        },
      })
    }
  }

  return (
    <StyledAlertCard
      metaProps={{ title: USER_EMAIL_ALERT_SUBSCRIPTION_TITLE, avatar: <Icon name="notifications" small /> }}
      actions={
        <Switch
          key="email-switch"
          value={Boolean(isSwitchChecked)}
          onChange={updateOrCreateEmailAlert}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          aria-label="Email alert switch"
          disabled={data?.dataAsset?.emailAlertEnabled === undefined || !!getDataAssetError}
        />
      }
      description={<EmailDescription assetName={data?.dataAsset?.name} isSwitchChecked={Boolean(isSwitchChecked)} />}
    />
  )
}
