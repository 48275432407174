import { useState } from "react"
import styled from "styled-components"
import { Button } from "antd"
import { CreateGXUserModal } from "src/DataAssets/connect-to-data/CreateGXUserModal"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { Link } from "src/ui/typography/Link/Link.tsx"

const BreathingRoom = styled.span`
  margin-top: ${({ theme }) => theme.spacing.vertical.xxxs};
`

const ExtraTextMap: Record<SupportedDataSource, string> = {
  DATABRICKS_SQL:
    "Create GX-specific users in your Databricks warehouse with adequate access and permission to centralize data governance at the source.",
  POSTGRES:
    "Create GX-specific users in your Postgres database with adequate access and permission to centralize data governance at the source.",
  SNOWFLAKE:
    "Create GX-specific users in your Snowflake warehouse with adequate access and permission to centralize data governance at the source.",
  SQL: "",
  SQLITE: "",
}

export function CreateGXUserExtra({ dataSourceType }: { dataSourceType: SupportedDataSource }) {
  const [isOpen, setIsOpen] = useState(false)
  const extraText = ExtraTextMap[dataSourceType]
  if (dataSourceType === "DATABRICKS_SQL") {
    return (
      <BreathingRoom>
        See instructions to generate an{" "}
        <Link
          to="https://docs.databricks.com/en/dev-tools/python-sql-connector.html#authentication"
          target="_blank"
          isLightText={true}
        >
          access token
        </Link>
        . {extraText}{" "}
        <Link
          to="https://docs.databricks.com/en/admin/users-groups/service-principals.html#manage-service-principals-in-your-account"
          target="_blank"
          isLightText={true}
        >
          See Databricks documentation
        </Link>
      </BreathingRoom>
    )
  }
  return (
    <BreathingRoom>
      {extraText}{" "}
      <Button type="text" size="small" title="See instructions" onClick={() => setIsOpen(true)}>
        See instructions
      </Button>
      <CreateGXUserModal dataSourceType={dataSourceType} isOpen={isOpen} onCancel={() => setIsOpen(false)} />
    </BreathingRoom>
  )
}
