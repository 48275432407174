import { useEffect } from "react"
import { LoadingState } from "src/ui/LoadingState"

import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { useNavigate } from "react-router-dom"
import { useOrganizations } from "src/organizations/useOrganizations"

export const DefaultRedirect = () => {
  const navigate = useNavigate()

  const { isAuthenticated, isLoading } = useCurrentUser()
  const { currentOrg } = useOrganizations()

  useEffect(() => {
    function getRedirectRoute() {
      if (!isAuthenticated) {
        return navigate("/start")
      }

      if (currentOrg?.name) {
        return navigate(`/organizations/${currentOrg?.name}/data-assets`)
      }

      return navigate("/login-success")
    }

    //only redirect if query loading is completed
    if (!isLoading) {
      getRedirectRoute()
    }
  }, [currentOrg?.name, isAuthenticated, isLoading, navigate])

  return <LoadingState loading={isLoading} />
}
