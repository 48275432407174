import { removeLocalStorageItem } from "src/common/utils/local-storage"
import { isEnableAgentRequestPendingVar, itemKey } from "src/DataAssets/connect-to-data/RequestAgentAlert"
import { useEffect } from "react"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

/**
 * Updates localstorage to reflect the "state of the world" at a given point in time.
 */
export function useCleanupEnableAgentRequestState() {
  const isAgentEnabled = useIsFeatureEnabled("forceEnableAgent")

  useEffect(() => {
    if (isAgentEnabled) {
      isEnableAgentRequestPendingVar(false)
      removeLocalStorageItem(itemKey)
    }
  }, [isAgentEnabled])
}
