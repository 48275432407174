import { Metric } from "src/api/graphql/graphql"
import { RowCondition } from "src/Expectation/CreateExpectationDrawer/types"

export const ROW_CONDITION_STRING_OPERATORS = ["!=", "<", "<=", "==", ">", ">=", ".notNull()"] as const
export type RowConditionStringOperator = (typeof ROW_CONDITION_STRING_OPERATORS)[number]

export const ROW_CONDITION_OBJECT_OPERATORS = [
  "!=",
  "<",
  "<=",
  "=",
  ">",
  ">=",
  "is after or on",
  "is after",
  "is before or on",
  "is before",
  "is not null",
  "is not on",
  "is not",
  "is on",
  "is",
] as const
export type RowConditionOperator = (typeof ROW_CONDITION_OBJECT_OPERATORS)[number]

export const COLUMN_TYPES = ["Bool", "DateTime", "Numeric", "Text", "Unknown"] as const
export type ColumnType = (typeof COLUMN_TYPES)[number]

export const COLUMN_TYPE_HELPERS: Record<
  ColumnType,
  {
    examples: string[]
    regex: RegExp
    operators: RowConditionOperator[]
  }
> = {
  Text: {
    examples: [
      "CHAR",
      "CHARACTER VARYING",
      "CHARACTER",
      "CLOB",
      "LONGTEXT",
      "MEDIUMTEXT",
      "NATIONAL CHARACTER VARYING",
      "NATIONAL CHARACTER",
      "NCHAR",
      "NCLOB",
      "NTEXT",
      "NVARCHAR",
      "STRING",
      "TEXT",
      "TINYTEXT",
      "TSQUERY",
      "TSVECTOR",
      "UUID",
      "VARCHAR",
    ],
    regex: /char|text|clob|tsvector|tsquery|string|uuid/i,
    operators: ["is", "is not", "is not null"],
  },

  DateTime: {
    examples: [
      "DATE",
      "DATETIME",
      "DATETIME2",
      "INTERVAL DAY TO SECOND",
      "INTERVAL YEAR TO MONTH",
      "INTERVAL",
      "SMALLDATETIME",
      "TIME WITH TIME ZONE",
      "TIME",
      "TIMESTAMP WITH LOCAL TIME ZONE",
      "TIMESTAMP WITH TIME ZONE",
      "TIMESTAMP",
      "TIMESTAMPTZ",
      "TIMESTAMP_LTZ",
      "TIMESTAMP_NTZ",
      "TIMESTAMP_TZ",
      "TIMETZ",
    ],
    regex: /date|time|interval/i,
    operators: ["is after or on", "is after", "is before or on", "is before", "is not null", "is not on", "is on"],
  },
  Numeric: {
    examples: [
      "BIGINT",
      "BIGSERIAL",
      "BYTEINT",
      "DECIMAL",
      "DOUBLE PRECISION",
      "DOUBLE",
      "FLOAT",
      "FLOAT4",
      "FLOAT8",
      "INT",
      "INT2",
      "INT4",
      "INT8",
      "INTEGER",
      "MONEY",
      "NUMBER",
      "NUMBER",
      "NUMERIC",
      "REAL",
      "SERIAL",
      "SMALLINT",
      "SMALLMONEY",
      "TINYINT",
    ],
    regex: /int|decimal|numeric|float|double|real|money|number|serial/i,
    operators: ["!=", "<", "<=", "=", ">", ">=", "is not null"],
  },

  Bool: {
    examples: ["BIT", "BOOL", "BOOLEAN"],
    regex: /bool|bit/i,
    operators: ["is", "is not", "is not null"],
  },

  Unknown: {
    examples: ["ARRAY", "BINARY", "GEOMETRY", "JSON", "NOT A COLUMN TYPE", "XML"],
    regex: /./,
    operators: [...ROW_CONDITION_OBJECT_OPERATORS],
  },
} as const

function getColumnType(columnDataType: string): ColumnType {
  const { Text, Numeric, DateTime, Bool } = COLUMN_TYPE_HELPERS
  switch (true) {
    case Text.regex.test(columnDataType):
      return "Text"
    /** ordering here is important as INTERVAL columns will get matched by Numeric due to INT if date isn't tested first */
    case DateTime.regex.test(columnDataType):
      return "DateTime"
    case Numeric.regex.test(columnDataType):
      return "Numeric"
    case Bool.regex.test(columnDataType):
      return "Bool"
    default:
      return "Unknown"
  }
}
type Options = { value: string; label: string }

export function useColumnTypeOperators(
  data: Partial<RowCondition> | undefined,
  metrics: Metric[] | null,
  serverOperators: string[],
): {
  options: Options[]
  columnType: ColumnType
} {
  const name = data?.["column"]
  const metric = metrics?.find((metric) => metric.columnName === name)
  const type = getColumnType(metric?.columnDataType ?? "")
  const { operators } = COLUMN_TYPE_HELPERS[type]
  const options = serverOperators
    .filter((operator) => (operators as string[]).includes(operator))
    .map((value) => ({ value, label: value }))
  return { options, columnType: type }
}
