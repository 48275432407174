import { useMutation } from "@apollo/client"
import { Flex, Switch, Tooltip, Form, message } from "antd"
import { getHourlyIntervalFromCron, getNextRunFromCron } from "src/common/utils/cron"
import { formatLocalCalendarDateWithTimeAndTimeZone } from "src/common/utils/formatTime"
import { graphql } from "src/api/graphql/gql"
import { unmaskFragment, FragmentType } from "src/api/graphql/fragment-masking"
import { Icon } from "src/ui/Icon"
import { theme } from "src/ui/themes/theme"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { EditSchedulePopover } from "src/DataAssets/AssetDetails/Expectations/EditSchedulePopover"
import { BodyNormal } from "src/ui/typography/Text/Text"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"

export const ScheduleSummaryFragmentDocument = graphql(`
  fragment ScheduleSummary on Schedule {
    id
    isEnabled
    startTime
    schedule
    ...EditScheduleForm_Schedule
  }
`)

export const ToggleScheduleMutationDocument = graphql(`
  mutation ToggleSchedule($id: UUID!) {
    toggleSchedule(input: { scheduleId: $id }) {
      schedule {
        id
        isEnabled
        schedule
        startTime
      }
    }
  }
`)

export function SimpleScheduleSummary({
  schedule: maskedSchedule,
}: {
  schedule: FragmentType<typeof ScheduleSummaryFragmentDocument> | undefined | null
}) {
  const schedule = unmaskFragment(ScheduleSummaryFragmentDocument, maskedSchedule)
  const [toggleSchedule, { loading: toggleScheduleLoading }] = useMutation(ToggleScheduleMutationDocument, {
    onError: (e) => {
      message.error(`There was an error toggling schedule ${e.message}`, MESSAGE_DURATION_SECONDS)
    },
  })
  const isEditorRole = useRequireRole("EDITOR")

  const nextRunDateTime = schedule
    ? formatLocalCalendarDateWithTimeAndTimeZone(getNextRunFromCron(schedule?.schedule).toISOString())
    : ""
  const displayStartTime = `${(schedule?.startTime.toString() || "0").padStart(2, "0")}:00`
  const hourlyInterval = schedule ? getHourlyIntervalFromCron(schedule?.schedule) : ""
  const intervalDisplayString = `Every ${hourlyInterval} hour${hourlyInterval !== 1 ? "s" : ""}`

  return (
    <Form.Item style={{ margin: 0 }}>
      <Flex justify="space-between">
        <Flex gap={theme.spacing.horizontal.xxs} align="center">
          <Tooltip title="Validation schedule">
            <Icon small name="calendar" color={theme.colors.primaryColors.gxPrimaryMedium} />
          </Tooltip>
          {isEditorRole && (
            <Switch
              checked={schedule?.isEnabled}
              checkedChildren="Active"
              unCheckedChildren="Paused"
              onChange={() => schedule?.id && toggleSchedule({ variables: { id: schedule.id } })}
              loading={!schedule?.id || toggleScheduleLoading}
              aria-label="Schedule On/Off Toggle"
            />
          )}
          {schedule?.isEnabled ? (
            <BodyNormal>
              Next validation {nextRunDateTime} {/* maybe ad rel time */}
            </BodyNormal>
          ) : (
            <BodyNormal $color="colorTextTertiary">No upcoming validation</BodyNormal>
          )}
        </Flex>
        <Flex gap={theme.spacing.horizontal.xxs} align="center">
          <BodyNormal>{`${intervalDisplayString} at ${displayStartTime}`}</BodyNormal>
          {isEditorRole && <EditSchedulePopover schedule={schedule} />}
        </Flex>
      </Flex>
    </Form.Item>
  )
}
