import { useCallback, useEffect, useState } from "react"
import stringify from "json-stable-stringify"
import { AlertProps, Flex, message, Spin } from "antd"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import { DatasourcesDocument, DatasourcesQuery } from "src/api/graphql/graphql-operations"
import { AddAssetDrawerHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { demoDataSourceConfig } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { useAgentStatus } from "src/common/hooks/useAgentStatus"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { Drawer, ScrollableFlex } from "src/ui/Drawer/Drawer"
import { useAssetCreationJobStatus } from "src/DataAssets/connect-to-data/useAssetCreationJobStatus"
import { graphql } from "src/api/graphql/gql"
import { SelectTableNames } from "src/DataAssets/connect-to-data/select-table-names/SelectTableNames"
import { getAddressFromDataSource } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { pluralize } from "src/common/utils/strings"
import { GX_AGENT_DOCS_LINK, GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE } from "src/GXAgent/words"
import { LoadingOutlined } from "@ant-design/icons"
import { theme } from "src/ui/themes/theme"
import { findIndex } from "lodash-es"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { SelectAutogenerateExpectations } from "src/DataAssets/connect-to-data/SelectAutogenerateExpectations.tsx"

export const DatasourcesWithNameDocument = graphql(`
  query DatasourcesWithName {
    datasourcesV2 {
      id
      name
      assets {
        id
        name
      }
    }
  }
`)

export const CreateTestDemoDataSourceJobDocument = graphql(`
  mutation CreateTestDemoDataSourceJob($config: JSONString!) {
    createTestDatasourceJob(config: $config) {
      jobId
    }
  }
`)

export const CreateDemoDataSourceAndAssetsDocument = graphql(`
  mutation CreateDemoDataSourceAndAssets($input: CreateDataSourceAndAssetsInput!) {
    createDataSourceAndAssets(input: $input) {
      datasourceV2 {
        id
        name
        type
        assets {
          id
          name
          createdAt
        }
      }
    }
  }
`)

export const AddDemoDataAssetsDocument = graphql(`
  mutation AddDemoDataAssetsDocument($input: AddDataAssetsToDataSourceInput!) {
    addDataAssetsToDataSource(input: $input) {
      assets {
        id
        name
        datasourceId
        createdAt
      }
    }
  }
`)

interface AddDemoDataSourceWithAssetsProps {
  onFinish: () => void
}

const data = {
  type: "postgres",
  name: demoDataSourceConfig.name,
  connection_string: demoDataSourceConfig.connectionString,
} as const

const dataSourceAddress = getAddressFromDataSource(data)

export function AddDemoDataSourceWithAssets({ onFinish }: AddDemoDataSourceWithAssetsProps) {
  const [alertDescription, setAlertDescription] = useState<AlertProps["description"]>()
  const [tableNames, setTableNames] = useState<string[]>()
  const [selectedTableNames, setSelectedTableNames] = useState<string[]>([])
  const [isJobPending, setIsJobPending] = useState<boolean>(false)
  const [demoDataSourceId, setDemoDataSourceId] = useState<string | undefined>(undefined)
  const _isAgentActive = useAgentStatus()
  const isAgentActive = Boolean(_isAgentActive)
  const agentEnabled = useIsGXAgentEnabled()
  const isColumnChangeDetectionEnabled = useIsFeatureEnabled("columnChangeDetection")
  const [autogenerateExpectations, setAutogenerateExpectations] = useState<boolean>(isColumnChangeDetectionEnabled)

  const { data: datasources, loading: isLoadingDataSources } = useQuery(DatasourcesWithNameDocument)

  const [createJob, { data: testConnData, reset: resetTestConn }] = useMutation(CreateTestDemoDataSourceJobDocument, {
    variables: { config: JSON.stringify({ ...data, assets: [] }) },
  })

  const [saveDatasource, { reset: resetSaveDataSource, loading: isSavingDataSource }] = useMutation(
    CreateDemoDataSourceAndAssetsDocument,
    {
      update: (cache, result) => {
        const datasource = result.data?.createDataSourceAndAssets?.datasourceV2
        cache.updateQuery({ query: DatasourcesDocument }, (cachedQuery: DatasourcesQuery | null) => {
          if (!datasource || !cachedQuery) {
            return undefined
          }
          return {
            ...cachedQuery,
            datasourcesV2: [...cachedQuery.datasourcesV2, datasource],
          }
        })
      },
      onError: (error: ApolloError) => {
        setAlertDescription(error.message)
      },
      onCompleted: (result) => {
        const assets = result.createDataSourceAndAssets?.datasourceV2.assets
        void message.success(
          assets?.length === 1 ? `1 Data Asset added` : `${assets?.length} Data Assets added`,
          MESSAGE_DURATION_SECONDS,
        )
        onClose()
      },
    },
  )

  const [addDataAssets, { reset: resetAddDataAssets, loading: isAddingDataAsset }] = useMutation(
    AddDemoDataAssetsDocument,
    {
      update: (cache, result) => {
        const dataAssets = result.data?.addDataAssetsToDataSource?.assets
        cache.updateQuery({ query: DatasourcesWithNameDocument }, (cachedQuery) => {
          if (!dataAssets || !cachedQuery) {
            return null
          }
          const indexOfDataSource = findIndex(cachedQuery.datasourcesV2, ({ id }) => id === dataAssets[0]?.datasourceId)
          if (!(indexOfDataSource >= 0)) {
            return null
          }
          const updatedDataSource = cachedQuery.datasourcesV2[indexOfDataSource]
          return {
            ...cachedQuery,
            datasourcesV2: [
              ...cachedQuery.datasourcesV2.slice(0, indexOfDataSource),
              { ...updatedDataSource, assets: [...updatedDataSource.assets, ...dataAssets] },
              ...cachedQuery.datasourcesV2.slice(indexOfDataSource + 1),
            ],
          }
        })
      },
      onError: (error: ApolloError) => {
        setAlertDescription(error.message)
      },
      onCompleted: (result) => {
        const assets = result.addDataAssetsToDataSource?.assets
        void message.success(
          assets?.length === 1 ? `1 Data Asset added` : `${assets?.length} Data Assets added`,
          MESSAGE_DURATION_SECONDS,
        )
        onClose()
      },
    },
  )

  const onClose = useCallback(() => {
    resetTestConn()
    resetSaveDataSource()
    resetAddDataAssets()
    onFinish()
  }, [resetTestConn, resetSaveDataSource, resetAddDataAssets, onFinish])

  useEffect(() => {
    const doAsync = async () => {
      const runJobWithRunner = async () => {
        setIsJobPending(true)
        setAlertDescription(null)
        await createJob()
      }
      const runJobWithAgent = async () => {
        if (isAgentActive) {
          setIsJobPending(true)
          setAlertDescription(null)
          await createJob()
        }
      }
      const runJobFn = agentEnabled ? runJobWithAgent : runJobWithRunner
      await runJobFn()
    }

    void doAsync()
  }, [createJob, isAgentActive, agentEnabled])

  useEffect(() => {
    if (selectedTableNames.length) {
      setAlertDescription(undefined)
    }
  }, [selectedTableNames])

  useEffect(() => {
    const demoDataSource = datasources?.datasourcesV2?.find((item) => item.name === data.name)
    if (demoDataSource) {
      setDemoDataSourceId(demoDataSource.id)
    }
  }, [datasources])

  const onSubmit = useCallback(async () => {
    if (selectedTableNames.length < 1) {
      setAlertDescription("Select at least one table")
      return
    }

    demoDataSourceId
      ? await addDataAssets({
          variables: {
            input: {
              dataSourceId: demoDataSourceId,
              tableNames: selectedTableNames,
              ...(isColumnChangeDetectionEnabled && { autogenerateExpectations }),
            },
          },
        })
      : await saveDatasource({
          variables: {
            input: {
              config: stringify(data),
              tableNames: selectedTableNames,
              ...(isColumnChangeDetectionEnabled && { autogenerateExpectations }),
            },
          },
        })
  }, [
    addDataAssets,
    saveDatasource,
    selectedTableNames,
    demoDataSourceId,
    isColumnChangeDetectionEnabled,
    autogenerateExpectations,
  ])

  useAssetCreationJobStatus({
    jobId: isJobPending ? testConnData?.createTestDatasourceJob?.jobId : undefined,
    onError: ({ message }) => setAlertDescription(message),
    onComplete: (job) => {
      if (job?.tableNames?.length && job.tableNames.length > 0) {
        setTableNames(job.tableNames as string[]) // we need to fix this on the server
      }
      setIsJobPending(false)
      resetTestConn()
    },
  })

  const submitText: string = `Add ${selectedTableNames.length} ${pluralize("Asset", selectedTableNames.length)}`

  return (
    <>
      <ScrollableFlex vertical gap="middle">
        <AddAssetDrawerHeader
          dataSourceType="POSTGRES"
          dataSourceName={data.name as string}
          dataSourceAddress={dataSourceAddress}
        />
        {isJobPending && !tableNames && (
          <Flex gap="small" align="center">
            <Spin
              indicator={
                <LoadingOutlined
                  spin
                  style={{
                    fontSize: theme.typography.fontSize.large,
                    color: theme.colors.primaryColors.gxAccentMedium,
                  }}
                />
              }
            />
            <p>Loading tables</p>
          </Flex>
        )}
        {tableNames && (
          <>
            <div style={{ flexGrow: 0.95, overflowY: "scroll" }}>
              <SelectTableNames tableNames={tableNames} setSelectedTableNames={setSelectedTableNames} search={false} />
            </div>
            {alertDescription && (
              <AlertBanner message="There was an error saving your Data Source." description={alertDescription} />
            )}
          </>
        )}
        {isColumnChangeDetectionEnabled && (
          <SelectAutogenerateExpectations value={autogenerateExpectations} setValue={setAutogenerateExpectations} />
        )}

        {!isAgentActive && (
          <AlertBanner message={GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE} description={GX_AGENT_DOCS_LINK} />
        )}
      </ScrollableFlex>

      <Drawer.Footer>
        <Drawer.FooterButton onClick={() => onClose()}>Back</Drawer.FooterButton>
        <Drawer.FooterButton
          type="primary"
          loading={isJobPending || isSavingDataSource || isAddingDataAsset || isLoadingDataSources}
          onClick={() => onSubmit()}
          disabled={isJobPending || isSavingDataSource || isAddingDataAsset || !isAgentActive}
        >
          {submitText}
        </Drawer.FooterButton>
      </Drawer.Footer>
    </>
  )
}
