import React from "react"
import GeLogoWithLightText from "src/ui/Image/assets/image/geLogoColorFull.svg?react"
import GeLogoWithDarkText from "src/ui/Image/assets/image/geLogoWithDarkText.svg?react"
import GeLogoSmall from "src/ui/Image/assets/image/geLogoColorSmall.svg?react"
import Maintenance from "src/ui/Image/assets/image/maintenance.svg?react"
import GEText from "src/ui/Image/assets/image/Text.svg?react"
import Approve from "src/ui/Image/assets/image/approve.svg?react"
import Close from "src/ui/Image/assets/image/close.svg?react"
import GithubLogo from "src/ui/Image/assets/image/githubLogo.svg?react"
import GoogleLogo from "src/ui/Image/assets/image/googleLogo.svg?react"
import GoogleLogoColored from "src/ui/Image/assets/image/googleLogoTransparentBackground.svg?react"
import AdditionalInfo from "src/ui/Image/assets/image/additional-info.svg?react"
import Link from "src/ui/Image/assets/image/link.svg?react"
import graphPerson from "src/ui/Image/assets/image/graphPerson.svg?react"
import pipelinePerson from "src/ui/Image/assets/image/pipelinePerson.svg?react"
import Code from "src/ui/Image/assets/image/code.svg?react"
import Slack from "src/ui/Image/assets/image/slack.svg?react"
import Batch from "src/ui/Image/assets/image/batch.svg?react"
import SuccessIcon from "src/ui/Image/assets/image/success.svg?react"
import FailureIcon from "src/ui/Image/assets/image/failure.svg?react"
import Numeric from "src/ui/Image/assets/image/numeric.svg?react"

interface Image {
  src: React.FunctionComponent
  ariaLabel: string
  alt: string
}

export const images = {
  geLogoWithLightText: {
    src: GeLogoWithLightText,
    ariaLabel: "Great Expectations Logo",
    alt: "Great Expectations Logo",
  },
  geLogoWithDarkText: {
    src: GeLogoWithDarkText,
    ariaLabel: "Great Expectations Logo",
    alt: "Great Expectations Logo",
  },
  geLogoSmall: {
    src: GeLogoSmall,
    ariaLabel: "Great Expectations Logo",
    alt: "Great Expectations Logo",
  },
  geText: {
    src: GEText,
    ariaLabel: "Great Expectations",
    alt: "Great Expectations",
  },
  graphPerson: {
    src: graphPerson,
    ariaLabel: "Person holding a graph",
    alt: "Person holding a graph",
  },
  pipelinePerson: {
    src: pipelinePerson,
    ariaLabel: "Person and data pipeline",
    alt: "Person and data pipeline",
  },
  additionalInfo: {
    src: AdditionalInfo,
    ariaLabel: "Additional info",
    alt: "Additional info",
  },
  approve: {
    src: Approve,
    ariaLabel: "Approve",
    alt: "Approve",
  },
  close: {
    src: Close,
    ariaLabel: "Close",
    alt: "Close",
  },
  githubLogo: {
    src: GithubLogo,
    ariaLabel: "GitHub Logo",
    alt: "GitHub Logo",
  },
  googleLogo: {
    src: GoogleLogo,
    ariaLabel: "Google Logo",
    alt: "Google Logo",
  },
  googleLogoColored: {
    src: GoogleLogoColored,
    ariaLabel: "Google Logo",
    alt: "Google Logo",
  },
  maintenance: {
    src: Maintenance,
    ariaLabel: "Maintenance tools",
    alt: "Maintenance tools",
  },
  link: {
    src: Link,
    ariaLabel: "Link",
    alt: "Link",
  },
  code: {
    src: Code,
    ariaLabel: "Code",
    alt: "Code",
  },
  slack: {
    src: Slack,
    ariaLabel: "Slack",
    alt: "Slack",
  },
  batch: {
    src: Batch,
    ariaLabel: "Batch",
    alt: "Batch",
  },
  success: {
    src: SuccessIcon,
    ariaLabel: "Success",
    alt: "Success",
  },
  failure: {
    src: FailureIcon,
    ariaLabel: "Failure",
    alt: "Failure",
  },
  numeric: {
    src: Numeric,
    ariaLabel: "Hashtag icon",
    alt: "Numeric",
  },
} as const satisfies Record<string, Image>
