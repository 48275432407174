import Slack from "src/ui/Image/assets/image/slack.svg?react"
import MSTeams from "src/ui/Image/assets/image/msTeams.svg?react"
import PagerDuty from "src/ui/Image/assets/image/pagerDuty.svg?react"
import { Icon } from "src/ui/Icon"
import { Flex } from "antd"
import { useTheme } from "styled-components"
import { BodyNormal } from "src/ui/typography/Text/Text"
import { Button } from "src/ui/Button/Button"

// TODO: these are temporary types until we have a proper GQL type for the email alerts
export type DomainType = "Generic" | "Slack" | "Teams" | "PagerDuty"

export type EmailAlertsUnion =
  | {
      __typename: "SlackEmailAlert"
      id: string
      emailAddress: string
      domain: DomainType
      workspace: string
      channel: string
    }
  | {
      __typename: "TeamsEmailAlert"
      id: string
      emailAddress: string
      domain: DomainType
      community: string
      channel: string
    }
  | {
      __typename: "PagerDutyEmailAlert"
      id: string
      emailAddress: string
      domain: DomainType
      username: string
    }
  | {
      __typename: "EmailAlert"
      id: string
      emailAddress: string
      domain: DomainType
    }

type EmailListProps = {
  emailAlert: EmailAlertsUnion
  onDelete?: () => void
}

export function IncidentAlertItem({ emailAlert, onDelete }: EmailListProps) {
  const theme = useTheme()
  const config = getIncidentAlertLayoutConfig(emailAlert)
  return (
    <Flex justify="space-between" style={{ width: "100%" }}>
      <Flex align="center" gap={theme.spacing.horizontal.xxxs}>
        {config.icon}
        <BodyNormal>{config.firstSection}</BodyNormal>
        {config.secondSection && <BodyNormal> - {config.secondSection}</BodyNormal>}
      </Flex>
      {onDelete && <Button type="text" icon="trash" onClick={onDelete} />}
    </Flex>
  )
}

function getIncidentAlertLayoutConfig(emailAlert: EmailAlertsUnion) {
  switch (emailAlert.__typename) {
    case "SlackEmailAlert":
      return {
        icon: <Slack />,
        firstSection: emailAlert.workspace,
        secondSection: `#${emailAlert.channel}`,
      }
    case "TeamsEmailAlert":
      return {
        icon: <MSTeams />,
        firstSection: emailAlert.community,
        secondSection: `#${emailAlert.channel}`,
      }
    case "PagerDutyEmailAlert":
      return {
        icon: <PagerDuty />,
        firstSection: emailAlert.username,
      }
    case "EmailAlert":
      return {
        icon: <Icon name="mail" style={{ paddingTop: "6px" }} size="18px" />,
        firstSection: emailAlert.emailAddress,
      }
  }
}
