import { endsWith, startsWith } from "lodash-es"
import { Sentry } from "src/observability/sentry/sentry"

export const errorMessageToIgnoreInSentry = [
  // Failed to fetch errors are common and not actionable
  // Can arise from a user navigating away from a page before a fetch completes
  // or user network connectivity issues
  "An alert with this name already exists. Enter an alternative name for the alert.",
  "TypeError: Failed to fetch",
  "Failed to fetch",
  "NetworkError when attempting to fetch resource", // firefox bug https://gx-cloud.sentry.io/issues/3634951286
  "ResizeObserver", // antd bug? not impacting users https://gx-cloud.sentry.io/issues/3943410297
  "Expectation Suite with id %s not found", // Triggered when a Suite is not found. in src/expectationSuites/ExpectationSuiteDetails/ExpectationSuiteDetails.tsx
  "DomainErrors(errs=[", // domain errors are a backend concern
  "Failed to delete Asset due to the following error(s): Expectation Suite is used by",
  "Failed to create Datasource due to the following error(s): Datasource with name %s already exists.",
]

/**
 * Helper function to match an incoming error message with a message that we want to ignore. The message to match can contain a wildcard (%s) that represents a variable.
 * @returns - A boolean indicating if the incoming error message matches the error message to ignore.
 */
export const shouldSuppressEvent = (event: Sentry.Event): boolean => {
  const message = event.message
  if (!message) return false

  return errorMessageToIgnoreInSentry.some((errorMessageToMatch) => {
    const lowerCasedIncomingErrorMessage = message.toLowerCase()
    const lowerCasedErrorMessageToMatch = errorMessageToMatch.toLowerCase()
    const splitMessage = lowerCasedErrorMessageToMatch.toLowerCase().split("%s")
    if (splitMessage.length > 2) {
      console.error(
        "matchErrorMessage function error: You should only use one wildcard (%s) in the error message to match",
      )
    }
    const [firstPartOfMessageToMatch, lastPartOfMessageToMatch] = splitMessage

    if (lastPartOfMessageToMatch) {
      return (
        startsWith(lowerCasedIncomingErrorMessage, firstPartOfMessageToMatch) &&
        endsWith(lowerCasedIncomingErrorMessage, lastPartOfMessageToMatch)
      )
    }
    return lowerCasedIncomingErrorMessage.includes(lowerCasedErrorMessageToMatch)
  })
}
