import { emailRegex } from "src/common/utils/emailValidationRegex"

export const INVALID_EMAIL_MESSAGE = "Please enter a valid email address"

export const validateEmailAddress = (_: unknown, value: string) => {
  // Custom form validator returns promises, see https://ant.design/components/form#formlist > rules
  if (!value) return Promise.reject(INVALID_EMAIL_MESSAGE)
  const isEmailInvalid = value.toLowerCase().match(emailRegex)
  if (!isEmailInvalid) return Promise.reject(INVALID_EMAIL_MESSAGE)
  return Promise.resolve()
}
