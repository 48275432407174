import { AutoComplete, Flex, Form, Input } from "antd"
import { useEffect, useState } from "react"
import { EmailAlertsUnion, IncidentAlertItem } from "src/Alerts/IncidentAlertSection/IncidentAlertItem"
import { validateEmailAddress } from "src/Alerts/IncidentAlertSection/utils"
import { Button } from "src/ui/Button/Button"
import { useTheme } from "styled-components"

const EMAIL_ADDRESS_INPUT_PLACEHOLDER = "Select from list or type new email address"
export const ARIA_LABEL_ADD_NEW_EMAIL_ADDRESS = "Add a new email address"

export function AddNewAlert() {
  const theme = useTheme()
  const [addAlert, setAddAlert] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    return () => {
      form.resetFields()
    }
  })

  // TODO: Remove this after implementing the new query
  const existingAlerts: EmailAlertsUnion[] = []

  const existingAlertOptions = existingAlerts.map((alert) => ({
    value: alert.emailAddress,
    label: <IncidentAlertItem emailAlert={alert} />,
  }))

  const onSubmit = (values: string) => {
    // TODO: Add mutation
    // eslint-disable-next-line no-console
    console.log({ onSubmit: values })
  }

  return (
    <>
      {addAlert ? (
        <Form onFinish={onSubmit} form={form}>
          <Flex style={{ width: "100%" }} vertical gap={theme.spacing.vertical.xs}>
            <Form.Item
              name="emailAddress"
              rules={[{ validator: validateEmailAddress }]}
              validateTrigger={["onBlur", "submit"]}
              aria-label={ARIA_LABEL_ADD_NEW_EMAIL_ADDRESS}
            >
              <AutoComplete
                style={{ width: "100%" }}
                showSearch
                allowClear
                placeholder={EMAIL_ADDRESS_INPUT_PLACEHOLDER}
                filterOption
                options={existingAlertOptions}
                notFoundContent={null}
              >
                <Input />
              </AutoComplete>
            </Form.Item>
            <Flex gap={theme.spacing.horizontal.xs} justify="center">
              <Button size="middle" onClick={() => setAddAlert(true)} onClickCapture={() => setAddAlert(false)}>
                Cancel
              </Button>
              <Button size="middle" icon="save" htmlType="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </Form>
      ) : (
        <Flex justify="center">
          <Button size="middle" icon="plus" onClick={() => setAddAlert(true)}>
            Add
          </Button>
        </Flex>
      )}
    </>
  )
}
