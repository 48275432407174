import { Form, Space } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"

import { graphql } from "src/api/graphql"

import {
  DrawerPage,
  emptyExpectation,
  useCreateExpectationDrawerContext,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import {
  SaveExpectationFooter,
  SelectExpectationFooter,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerFooters.tsx"
import { ExpectationPicker } from "src/Expectation/CreateExpectationDrawer/ExpectationPicker"
import { SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/types"
import {
  CREATE_EXPECTATION_DRAWER_TITLE,
  CREATE_EXPECTATION_ERROR,
} from "src/Expectation/CreateExpectationDrawer/words"
import { CreateExpectationFeedbackButton } from "src/Expectation/CreateExpectationDrawer/CreateExpectationFeedback"
import { NODE_ENV } from "src/common/env"
import { useIsDemoData } from "src/common/hooks/useIsDemoData"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { getIsDateTimeColumn } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { Drawer } from "src/ui/Drawer/Drawer"
import { theme } from "src/ui/themes/theme"
import {
  CamelCasedExpectationType,
  ExpectationTitle,
  getExpectationMetaInfoFromType,
} from "src/schemas/expectation-metadata-utils"
import { CreateExpectationDrawerAlert } from "src/Alerts/DemoData/CreateExpectationDrawerAlert"
import { LoadingState } from "src/ui/LoadingState"
import { AlertBanner } from "src/ui/Alert"
import { ExpectationEditor } from "src/Expectation/ExpectationEditor"
import { camelCase } from "lodash-es"
import BatchDefinitionForm from "src/Expectation/CreateExpectationDrawer/BatchDefinitionForm"
import { BatchDefinitionDescription } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionDescription"
import { useDemoDataAssetName } from "src/common/hooks/useDemoDataAssetName"

interface CreateExpectationDrawerProps {
  onClose: () => void
  onSave: (addMore?: "addMore") => Promise<void>
  expectationSuiteSelectorForDrawer: JSX.Element
  loading: boolean
  error?: string
  variant: string
}

const DataAssetWithLatestMetricsRunDocument = graphql(`
  query dataAssetWithLatestMetricsRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      latestMetricRun {
        dataAssetId
        metrics {
          columnDataType
          columnName
          mean
          median
          nullCount
          valueRangeMax
          valueRangeMin
          valueRangeMaxUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
          valueRangeMinUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
        }
        rowCount
      }
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

function CreateExpectationDrawer({
  onClose,
  onSave,
  expectationSuiteSelectorForDrawer,
  loading,
  error,
  variant,
}: CreateExpectationDrawerProps) {
  const {
    open,
    pageNumber,
    setPageNumber,
    suiteName,
    selectedExpectation,
    setSelectedExpectation,
    jsonValue,
    setJsonValue,
    form,
    suiteId,
    batchDefinitionForm,
    batchDefinitionData,
    setBatchDefinitionData,
  } = useCreateExpectationDrawerContext()

  const [expectationEditorError, setExpectationEditorError] = useState<string | undefined>("")

  const { assetId: _assetId = "" } = useParams<{
    assetId: string
  }>()
  const dataAssetId = decodeURIComponent(_assetId)

  const { data: dataAssetQueryResult } = useQuery(DataAssetWithLatestMetricsRunDocument, {
    variables: {
      id: dataAssetId,
    },
    skip: !dataAssetId,
  })

  const isDateTimeColumn = getIsDateTimeColumn(dataAssetQueryResult?.dataAsset?.latestMetricRun?.metrics)

  const showBatchDefinition = isDateTimeColumn && !suiteId

  useEffect(() => {
    const resetFormValues = () => {
      setPageNumber(DrawerPage.ExpectationPicker)
      setSelectedExpectation(emptyExpectation)
      setJsonValue("")
    }

    if (!open) {
      resetFormValues()
    }
  }, [open, jsonValue, setJsonValue, setPageNumber, setSelectedExpectation])

  const handleExpectationSelect = (clickedExpectation: SelectedExpectation) => {
    if (clickedExpectation.value !== selectedExpectation.value) {
      // Reset the form when a new expectation is selected, preserve state if the same expectation is clicked again
      setSelectedExpectation(clickedExpectation)
      setJsonValue("")
    }
    if (clickedExpectation.title && suiteName) {
      pageNumber !== DrawerPage.ExpectationEditor && setPageNumber(DrawerPage.ExpectationEditor)
    }
  }

  const drawerTitle = useMemo(() => {
    if (NODE_ENV === "development") {
      return (
        <>
          {CREATE_EXPECTATION_DRAWER_TITLE}: <span style={{ color: theme.colors.error.gxError }}>{variant}</span>
        </>
      )
    }
    return CREATE_EXPECTATION_DRAWER_TITLE
  }, [variant])

  const getFooterForCurrentPage = () => {
    if (pageNumber === DrawerPage.ExpectationPicker) {
      return <SelectExpectationFooter onSelectExpectation={handleExpectationSelect} />
    }
    if (pageNumber === DrawerPage.ExpectationEditor) {
      return (
        <SaveExpectationFooter
          onBack={() => {
            setPageNumber(DrawerPage.ExpectationPicker)
          }}
          onSave={onSave}
          onSaveAndAdd={() => {
            onSave("addMore")
          }}
          saveLoading={loading}
        />
      )
    }
  }

  const { splitter, splitterMethod } = useGetSplitterData({ isVisible: true })

  const isDemoData = useIsDemoData(dataAssetQueryResult?.dataAsset)
  const assetName = useDemoDataAssetName(dataAssetQueryResult?.dataAsset)

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      size="large"
      footer={getFooterForCurrentPage()}
      destroyOnClose
      open={open}
      onClose={onClose}
      extra={<CreateExpectationFeedbackButton />}
    >
      <Form form={form} name="createExpectation" preserve={true} layout="vertical">
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          {isDemoData && (
            <Space direction="vertical" style={{ marginBottom: theme.spacing.vertical.xs }}>
              <CreateExpectationDrawerAlert
                page={pageNumber === 1 ? "picker" : "editor"}
                assetName={assetName}
                selectedExpectationTitle={selectedExpectation.title as ExpectationTitle}
              />
            </Space>
          )}
          {pageNumber === DrawerPage.ExpectationPicker && <>{expectationSuiteSelectorForDrawer}</>}
          <ExpectationPicker onSelectExpectation={handleExpectationSelect} />
        </Space>
        {pageNumber === DrawerPage.ExpectationEditor && (
          <>
            {selectedExpectation.title ? (
              <>
                <>
                  {showBatchDefinition &&
                    (splitter && splitterMethod ? (
                      <BatchDefinitionDescription style={{ paddingBottom: theme.spacing.vertical.l }} />
                    ) : (
                      <BatchDefinitionForm
                        batchDefinitionForm={batchDefinitionForm}
                        batchDefinitionData={batchDefinitionData}
                        setBatchDefinitionData={setBatchDefinitionData}
                      />
                    ))}
                </>
                <ExpectationEditor
                  value={jsonValue}
                  onChange={(newJson, error) => {
                    setExpectationEditorError(error)
                    setJsonValue(newJson)
                  }}
                  metaInfo={getExpectationMetaInfoFromType(
                    camelCase(selectedExpectation.value) as CamelCasedExpectationType,
                  )} // casts are bad, and this is a "no worse than before" modification; ExpectationEditor gets stronger typing in this change
                  codeSnippetEditorProps={{ width: "90%" }}
                  dataAssetWithLatestMetricRun={dataAssetQueryResult?.dataAsset}
                />
                {expectationEditorError && (
                  <div>
                    <AlertBanner type="error" message={expectationEditorError} />
                  </div>
                )}
                {error && <AlertBanner message={CREATE_EXPECTATION_ERROR} description={error} />}
              </>
            ) : (
              <LoadingState loading={loading} />
            )}
          </>
        )}
      </Form>
    </Drawer>
  )
}

export { CreateExpectationDrawer }
