import { Flex, ModalFuncProps, Space } from "antd"
import { AppModalConfig } from "src/ui/AppModal/AppModal"
import { BodyNormal, Heading1 } from "src/ui/typography/Text/Text"
import { Helmet } from "react-helmet-async"
import { Link } from "src/ui/typography/Link/Link.tsx"
import { Emoji } from "src/ui/Image/Emoji.tsx"
import styled from "styled-components"

export const SalesDemoModalConfig: ModalFuncProps = {
  ...AppModalConfig,
  width: 1100,
  okButtonProps: { style: { display: "none" } },
  title: <Title />,
  content: <Content />,
}

export const SALES_DEMO_TITLE = "Schedule a demo"

function Title() {
  return (
    <Flex align="center" justify="center">
      <Heading1>
        <Space direction="horizontal">
          <Emoji label="light bulb" symbol={128161} />
          {SALES_DEMO_TITLE}
        </Space>
      </Heading1>
    </Flex>
  )
}

const StyledMeetingDescriptionContainer = styled(Flex)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const StyledMeetingDescription = styled(BodyNormal)`
  max-width: 850px;
`

export const SALES_DEMO_DESCRIPTION =
  "Schedule an onboarding session with GX engineers and developer advocates to get past any blockers and learn how to make the most out of GX Cloud."

export const SALES_DEMO_SUPPORT_EMAIL = "allison.hirata@greatexpectations.io"

const MeetingDescription = () => {
  return (
    <StyledMeetingDescriptionContainer justify="center">
      <StyledMeetingDescription>
        <p>{SALES_DEMO_DESCRIPTION}</p>
        <p>
          Please email <Link to={`mailto:${SALES_DEMO_SUPPORT_EMAIL}`}>{SALES_DEMO_SUPPORT_EMAIL}</Link> if you need
          help with scheduling.
        </p>
      </StyledMeetingDescription>
    </StyledMeetingDescriptionContainer>
  )
}

export function Content() {
  return (
    <Flex vertical justify="space-between">
      <MeetingDescription />
      <Helmet>
        <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
      </Helmet>
      <div
        style={{ height: "680px" }}
        className="meetings-iframe-container"
        //data-src="https://meetings.hubspot.com/kim-mathieu?embed=true" // TEST CALENDAR
        // data-src="https://meetings.hubspot.com/kyle-eaton?embed=true" // TEST CALENDAR
        data-src="https://meetings.hubspot.com/doug-cohen2/request-a-demo-pq?uuid=c6cafe2a-b598-448f-9b8d-e42aea6fc266&embed=true" // REAL CALENDAR
      />
    </Flex>
  )
}
