import { Row } from "antd"
import styled from "styled-components"
import { DescriptionsItemType } from "antd/lib/descriptions/index"
import { getImageLogo } from "src/DataAssets/getImageLogo"
import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils"
import { DataAssetConfigData } from "src/DataAssets/connect-to-data/schemas/data-asset-schemas"
import { RecursivePartial } from "src/common/utils/recursive-partial"
import { graphql } from "src/api/graphql/gql"
import { Maybe } from "src/api/graphql/graphql"
import { Param } from "src/ui/Param/Param"
import { getSplitterColumn, useGetBatchDefinition } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { APP_CALENDAR_DATE_WITH_YEAR_FORMAT } from "src/common/config"
import { LG } from "src/ui/typography/Text/Text"

// TODO: solve this with antd instead of styled-components
const StyledLogo = styled.img`
  width: ${({ theme }) => theme.spacing.scale.xs};
  height: ${({ theme }) => theme.spacing.scale.xs};
  padding-right: ${({ theme }) => theme.spacing.vertical.xxs};
`

const AssetSummaryDescriptionItems_DataAssetFragmentDocument = graphql(`
  fragment AssetSummaryDescriptionItems_DataAsset on AssetRef {
    createdAt
    config
    datasourceV2 {
      type
      name
    }
    splitter {
      __typename
      ...SplitterUtils_Splitter
    }
  }
`)

export function useAssetSummaryDescriptionItems(
  maskedDataAsset: Maybe<FragmentType<typeof AssetSummaryDescriptionItems_DataAssetFragmentDocument>> | undefined,
): DescriptionsItemType[] {
  const dataAsset = unmaskFragment(AssetSummaryDescriptionItems_DataAssetFragmentDocument, maskedDataAsset)
  const createdOnDate = dataAsset?.createdAt
    ? Intl.DateTimeFormat([], APP_CALENDAR_DATE_WITH_YEAR_FORMAT).format(new Date(dataAsset.createdAt))
    : "Unknown"
  const dataSourceType = dataAsset?.datasourceV2.type

  const splitter = dataAsset?.splitter
  const { column: splitterColumn, isMulticolumn: isMulticolumnSplitter } = getSplitterColumn(splitter)
  const batchDefinition = useGetBatchDefinition(splitter?.__typename)
  const dataAssetConfig = parseJSONStringConfig(dataAsset?.config ?? "{}") as RecursivePartial<DataAssetConfigData>
  const batchInfoLabel = "Batch interval"
  const batchColumnLabel = `${"Batch column"}${isMulticolumnSplitter ? "s" : ""}`

  return [
    {
      label: "Data Source",
      children: (
        <Row wrap={false}>
          {dataSourceType && <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />}
          <LG style={{ wordBreak: "break-word" }}>{dataAsset?.datasourceV2?.name}</LG>
        </Row>
      ),
    },
    ...(dataAssetConfig.type === "table"
      ? [
          {
            label: "Table name",
            children: dataAssetConfig.table_name,
          },
        ]
      : []),
    ...(dataAssetConfig.type === "query"
      ? [
          {
            label: "Query",
            children: <Param>{dataAssetConfig.query}</Param>,
          },
        ]
      : []),
    ...(batchDefinition
      ? [
          {
            label: batchInfoLabel,
            children: batchDefinition,
          },
        ]
      : []),
    ...(splitterColumn
      ? [
          {
            label: batchColumnLabel,
            children: <Param>{splitterColumn}</Param>,
          },
        ]
      : []),
    {
      label: "Date created",
      children: createdOnDate,
    },
  ]
}
