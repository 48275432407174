import { Select, Space } from "antd"

import { get } from "lodash-es"
import {
  selectedExpectationFromSchema,
  useDataQualityFilteredExpectationOptions,
} from "src/common/hooks/useDataQualityFilteredExpectationOptions"
import { DataQualityIssuesCards } from "src/Expectation/CreateExpectationDrawer/DataQualityIssueBasedPicker/DataQualityIssuesCards"
import { SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/types"
import { BodyNormal } from "src/ui/typography/Text/Text"
import styled from "styled-components"
import { dqiPath } from "src/schemas/expectation-metadata-utils"

const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.vertical.s};
`

type Props = {
  onSelectExpectation: (expectation: SelectedExpectation) => void
}

export function DataQualityIssueBasedPicker({ onSelectExpectation }: Props) {
  const { options, valueToSchema } = useDataQualityFilteredExpectationOptions()

  const handleChange = (title: string) => {
    const schema = valueToSchema[title]
    const [dataQualityIssue] = get(schema, `schema.${dqiPath}`) ?? []
    onSelectExpectation(selectedExpectationFromSchema(schema, dataQualityIssue))
  }

  return (
    <>
      <StyledSpace direction="vertical">
        <BodyNormal>Search for Expectation name by keyword</BodyNormal>
        <Select
          style={{ width: "100%" }}
          onChange={handleChange}
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          options={options}
          aria-label="Select an expectation"
        />
      </StyledSpace>
      <DataQualityIssuesCards onSelectExpectation={onSelectExpectation} />
    </>
  )
}
