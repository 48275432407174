/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Form, FormInstance } from "antd"
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState, useCallback } from "react"
import { jsonStringToFormObject, formObjectToJsonString } from "src/Expectation/ExpectationEditor"
import { JSONSchema } from "json-schema-to-ts"
import { ExpectationJsonSchema } from "src/Expectation/uiForms/ExpectationConfigForm"
import { useMutation } from "@apollo/client"
import { GetOrCreateScheduleDocument, UnpauseScheduleDocument } from "src/api/graphql/graphql-operations"
import { DEFAULT_SCHEDULE_FREQUENCY, generateCronWithCyclicHours, getNextHour } from "src/common/utils/cron"
import { ExpectationConfig, SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/types"
import { handleWindowedPayload } from "src/Expectation/CreateExpectationDrawer/windowedExpectationUtils"
import { handleRowConditionPayload } from "src/Expectation/uiForms/customRenderers/RowConditionControl/rowConditionParser"
import { SnakeCasedExpectationType, getJsonSchemaBySnakeCaseName } from "src/schemas/expectation-metadata-utils"

export enum DrawerPage {
  ExpectationPicker = 1,
  ExpectationEditor = 2,
}

export const emptyExpectation: SelectedExpectation = { value: "", dataQualityIssues: undefined, title: "" }

type BatchDefinitionData = {
  [key: string]: string | [key: string]
}

type CreateExpectationDrawerContextProps = {
  open: boolean
  onClose: () => void
  pageNumber: DrawerPage
  setPageNumber: (pageNumber: DrawerPage) => void
  suiteId: string
  setSuiteId: (suiteId: string) => void
  suiteName: string | null
  setSuiteName: (suiteName: string | null) => void
  selectedExpectation: SelectedExpectation
  setSelectedExpectation: (selectedExpectation: SelectedExpectation) => void
  jsonValue: string
  setJsonValue: (jsonValue: string) => void
  form: FormInstance
  batchDefinitionForm: FormInstance
  batchDefinitionData: BatchDefinitionData
  setBatchDefinitionData: (batchDefinition: object) => void
  config: string
  jsonSchema?: ExpectationJsonSchema | JSONSchema
  checkpointId?: string
  setCheckpointId: (checkpointId?: string) => void
  setScheduleIdAsUnpaused: (checkpointId?: string, isFirstExpectation?: boolean) => Promise<void>
}

const CreateExpectationDrawerContext = createContext<CreateExpectationDrawerContextProps | undefined>(undefined)

type CreateExpectationDrawerContextProviderProps = {
  open: boolean
  onClose: () => void
}

export const CreateExpectationDrawerContextProvider = ({
  open,
  onClose,
  children,
}: PropsWithChildren<CreateExpectationDrawerContextProviderProps>) => {
  const [pageNumber, setPageNumber] = useState(DrawerPage.ExpectationPicker)
  const [suiteId, setSuiteId] = useState<string>("")
  const [suiteName, setSuiteName] = useState<string | null>(null)
  const [checkpointId, setCheckpointId] = useState<string | undefined>("")
  const [selectedExpectation, setSelectedExpectation] = useState<SelectedExpectation>(emptyExpectation)
  const [jsonValue, setJsonValue] = useState("")
  const [form] = Form.useForm()
  const [batchDefinitionForm] = Form.useForm()
  const [batchDefinitionData, setBatchDefinitionData] = useState({})
  const [config, setConfig] = useState("")

  const patchWindowedPayload = useCallback(
    (configObj: ExpectationConfig) =>
      // casts are bad, and this one is necessary for this provider since handleWindowedPayload can have a stronger typed interface for its use in other components
      handleWindowedPayload(configObj, selectedExpectation.value as SnakeCasedExpectationType),
    [selectedExpectation],
  )

  useEffect(() => {
    const configObj = jsonStringToFormObject(jsonValue) || {}
    const windowedConfigObj = { expectation_type: selectedExpectation.value, ...patchWindowedPayload(configObj) }
    const newConfigObj = handleRowConditionPayload(windowedConfigObj)
    setConfig(formObjectToJsonString(newConfigObj))
  }, [selectedExpectation, jsonValue, patchWindowedPayload])

  const jsonSchema = useMemo(
    () => (selectedExpectation.value ? getJsonSchemaBySnakeCaseName(selectedExpectation.value) : undefined),
    [selectedExpectation.value],
  )

  const [unpauseScheduleMutation] = useMutation(UnpauseScheduleDocument, {
    onError: (error) => {
      console.error("error here: ", error.message)
    },
  })

  const [getOrCreateScheduleMutation] = useMutation(GetOrCreateScheduleDocument, {
    onError: (error) => {
      console.error("error here: ", error.message)
    },
    onCompleted: async (data) => {
      if (data.getOrCreateSchedule?.schedule?.isEnabled === false) {
        // if the schedule is paused, unpause it
        // this can happen if the schedule already exists but is paused
        await unpauseScheduleMutation({
          variables: {
            id: data.getOrCreateSchedule.schedule.id,
          },
        })
      }
    },
  })

  /**
   * Only set schedule as unpaused if the flag's enabled, and we are adding our first Expectation.
   */
  const setScheduleIdAsUnpaused = useCallback(
    async (checkpointId?: string, isFirstExpectation?: boolean) => {
      if (!isFirstExpectation || !checkpointId) {
        return
      }

      // Generate the values for default schedule as top of the next hour
      const nextHour = getNextHour(new Date().getHours())
      const cronExpression = generateCronWithCyclicHours({ start: nextHour, freq: DEFAULT_SCHEDULE_FREQUENCY })
      await getOrCreateScheduleMutation({
        variables: {
          input: {
            sourceResources: [
              {
                entityId: checkpointId,
                entityType: "Checkpoint",
              },
            ],
            isEnabled: true,
            schedule: cronExpression,
            startTime: nextHour,
          },
        },
      })
    },
    [getOrCreateScheduleMutation],
  )

  const value = {
    open,
    onClose,
    pageNumber,
    setPageNumber,
    suiteId,
    setSuiteId,
    suiteName,
    setSuiteName,
    selectedExpectation,
    setSelectedExpectation,
    jsonValue,
    setJsonValue,
    form,
    batchDefinitionForm,
    batchDefinitionData,
    setBatchDefinitionData,
    config,
    jsonSchema,
    checkpointId,
    setCheckpointId,
    setScheduleIdAsUnpaused,
  }

  return <CreateExpectationDrawerContext.Provider value={value}>{children}</CreateExpectationDrawerContext.Provider>
}

export const useCreateExpectationDrawerContext = () => {
  const context = useContext(CreateExpectationDrawerContext)

  if (context === undefined)
    throw new Error("useCreateExpectationDrawerContext must be used within a CreateExpectationDrawerContextProvider")

  return context
}
