import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { getMetaNotes } from "src/Expectation/utils.tsx"
import { handleRowConditionPayload } from "src/Expectation/uiForms/customRenderers/RowConditionControl/rowConditionParser"
import { constructToBeBetweenCombinator } from "src/Expectation/CreateExpectationDrawer/windowedExpectationUtils"

export const MapExpectationConfigToJsonConfig_ExpectationFragmentDocument = graphql(`
  fragment MapExpectationConfigToJsonConfig_Expectation on ExpectationConfiguration {
    expectationType
    description
    kwargs
    renderedContent {
      value {
        ... on GraphType {
          metaNotes
        }
        ... on StringValueType {
          metaNotes
        }
        ... on TableType {
          metaNotes
        }
      }
    }
  }
`)

export function mapExpectationConfigToJsonConfig(
  maskedExpectation: FragmentType<typeof MapExpectationConfigToJsonConfig_ExpectationFragmentDocument>,
) {
  const expectation = unmaskFragment(MapExpectationConfigToJsonConfig_ExpectationFragmentDocument, maskedExpectation)
  const description = expectation?.description
  const renderedContent = expectation?.renderedContent?.[0]
  const metaNotes = renderedContent ? getMetaNotes(renderedContent) : null
  let parsedKwargs = {}
  if (expectation.kwargs) {
    parsedKwargs = JSON.parse(expectation.kwargs)
  }
  const rowConditionConfig = handleRowConditionPayload(parsedKwargs)
  const constructedKwargs = constructToBeBetweenCombinator(rowConditionConfig, expectation?.expectationType)

  return {
    ...constructedKwargs,
    notes: metaNotes ?? "",
    description,
  }
}
