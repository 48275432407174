import styled from "styled-components"

import { Alert } from "antd"
import { PYTHON_QUICKSTART_URL } from "src/common/config"
import { Link } from "src/ui/typography/Link/Link.tsx"

const StyledAppAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => `${theme.spacing.horizontal.s}`};
`

export const CodeSnippetAlert = () => {
  return (
    <StyledAppAlert
      description={
        <div>
          Make sure <b>organization id</b> and <b>user access token</b> have been set in your development environment.
          Click{" "}
          <Link to={PYTHON_QUICKSTART_URL} target="_blank" rel="noopener noreferrer">
            here
          </Link>{" "}
          for detailed instructions.
        </div>
      }
      type="info"
      showIcon
    />
  )
}
