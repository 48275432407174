import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"

type ExpectationJsonSchemaObject = typeof jsonSchemas

type ExpectationsWithoutDataQualityIssues = {
  [P in keyof ExpectationJsonSchemaObject]: ExpectationJsonSchemaObject[P]["schema"]["properties"]["metadata"]["properties"] extends Readonly<{
    data_quality_issues: infer U
  }>
    ? U extends Readonly<{ const: ReadonlyArray<never> }>
      ? P
      : never
    : P
}[keyof ExpectationJsonSchemaObject]

type ExpectationJsonSchemasWithDataQualityIssues = Omit<typeof jsonSchemas, ExpectationsWithoutDataQualityIssues>

export type DataQualityIssue = {
  [P in keyof ExpectationJsonSchemasWithDataQualityIssues]: ExpectationJsonSchemasWithDataQualityIssues[P]["schema"]["properties"]["metadata"]["properties"]["data_quality_issues"]["const"][number]
}[keyof ExpectationJsonSchemasWithDataQualityIssues]

export const DataQualityIssues = [
  "SQL",
  "Schema",
  "Volume",
  "Uniqueness",
  "Validity",
  "Numeric",
  "Completeness",
] as const satisfies DataQualityIssue[]

type MissingDataQualityIssue =
  Exclude<DataQualityIssue, (typeof DataQualityIssues)[number]> extends never
    ? never
    : true | Exclude<DataQualityIssue, (typeof DataQualityIssues)[number]>

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-expect-error make sure our array has all the categories in it
const missingDataQualityIssue: MissingDataQualityIssue = true
/* eslint-enable @typescript-eslint/no-unused-vars */
