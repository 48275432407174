import { FragC } from "src/types/fragment"
import { ExpectationContainer, getMetaNotes, getRenderer, SpaceBetweenContainer } from "src/Expectation/utils"
import { ExpectationActions } from "src/Expectation/ExpectationActions"
import { MetaNotes } from "src/Expectation/MetaNotes"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { ExpectationChanges } from "src/api/graphql/graphql"
import { InteractiveExpectationFragment, InteractiveExpectationFragmentDoc } from "src/api/graphql/graphql-operations"
import styled from "styled-components"
import { ExpectationChangeHistory } from "src/Expectation/ExpectationChangeHistory"
import { ViewModes } from "src/ui/Button/ViewToggle"
import { Window } from "src/Expectation/CreateExpectationDrawer/types"

const StyledExpectationHistoryContainer = styled.div`
  width: 100%;
`

type ColumnListType =
  | {
      column_list?: {
        value: string[] | null
      }
    }
  | undefined

export interface ParamHistory {
  removed?: boolean
  added?: boolean
}

export type RenderState = "expected" | "unexpected" | "missing"

export type ParamTypes = Record<
  string,
  { value?: string | string[]; history?: ParamHistory; render_state?: RenderState } | undefined
> & {
  kwargs?: { value?: Record<string, string | string[]> }
  windows?: Window[]
} & ColumnListType

export type ExpectationProps = {
  expectation?: InteractiveExpectationFragment | null
}

type ExpectationViewProps = {
  queriesToRefetchOnDelete?: string[]
  viewMode?: ViewModes
  expectationChangeHistory?: ExpectationChanges
  isExpectationDeleted?: boolean
}

const Expectation: FragC<ExpectationProps, ExpectationViewProps> = ({
  queriesToRefetchOnDelete,
  expectation,
  viewMode = "currentView",
  expectationChangeHistory,
  isExpectationDeleted,
}) => {
  const description = expectation?.description
  const renderedContent = expectation?.renderedContent?.[0]
  const metaNotes = renderedContent ? getMetaNotes(renderedContent) : null
  const isEditor = useRequireRole("EDITOR")
  const expectationJson = JSON.stringify({
    kwargs: JSON.parse(expectation?.kwargs ?? "{}"),

    meta: {
      notes: metaNotes ?? "",
    },
  })

  return (
    <ExpectationContainer success={null}>
      <SpaceBetweenContainer>
        {viewMode === "changeLog" ? (
          <StyledExpectationHistoryContainer>
            {getRenderer({
              renderedValue: expectation?.renderedContent?.[0],
              fallback: expectation?.expectationType ?? "",
              isExpectationDeleted: isExpectationDeleted,
              isDeletionAction: isExpectationDeleted,
              kwargs: expectation?.kwargs,
              description,
            })}
            {!!expectationChangeHistory && expectationChangeHistory.changes.length > 0 && (
              <ExpectationChangeHistory
                changeHistory={expectationChangeHistory.changes}
                isExpectationDeleted={isExpectationDeleted}
              />
            )}
          </StyledExpectationHistoryContainer>
        ) : (
          <>
            {getRenderer({
              renderedValue: expectation?.renderedContent?.[0],
              fallback: expectation?.expectationType ?? "",
              kwargs: expectation?.kwargs,
              description,
            })}
            {isEditor && expectation?.expectationId && (
              <ExpectationActions
                // specifying the expectation this way is a little awkward, but required to assure compiler that expectationId is both present and non-null
                expectation={{ ...expectation, expectationId: expectation.expectationId }}
                exists
                queriesToRefetchOnDelete={queriesToRefetchOnDelete}
                renderedExpectation={{
                  renderedValue: renderedContent,
                  fallback: expectation.expectationType ?? "",
                  kwargs: expectation?.kwargs,
                }}
                expectationJson={expectationJson}
              />
            )}
          </>
        )}
      </SpaceBetweenContainer>
      {viewMode === "currentView" && metaNotes && <MetaNotes metaNotes={metaNotes} />}
    </ExpectationContainer>
  )
}

Expectation.fragments = {
  expectation: InteractiveExpectationFragmentDoc,
}

export { Expectation }
