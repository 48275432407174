import { DataQualityIssue } from "src/schemas/expectation-metadata-data-quality-issues"
import { SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/types"
import { Form, Select } from "antd"
import {
  selectedExpectationFromSchema,
  useDataQualityFilteredExpectationOptions,
} from "src/common/hooks/useDataQualityFilteredExpectationOptions"

type Props = {
  /** The selected category, if any, by which to filter options.
   * Some expectations can belong to multiple categories so it must be passed
   * separately from the selected expectation */
  selectedDataQualityIssue: DataQualityIssue | undefined
  /** Callback to handle when an expectation is selected */
  onSelectExpectation: (expectation: SelectedExpectation) => void
  /** The selected expectation, if any */
  selectedExpectation: SelectedExpectation | undefined
}

export function DataQualityExpectationSelect({
  selectedDataQualityIssue,
  onSelectExpectation,
  selectedExpectation,
}: Props) {
  const { options, valueToSchema } = useDataQualityFilteredExpectationOptions(selectedDataQualityIssue)

  const handleChange = (title: string) => {
    onSelectExpectation(selectedExpectationFromSchema(valueToSchema[title], selectedDataQualityIssue))
  }

  return (
    <Form.Item label="Expect" htmlFor="select-expectation">
      <Select
        id="select-expectation"
        options={options}
        onChange={handleChange}
        value={selectedExpectation ? selectedExpectation.title : options[0].value}
        araia-label="Select expectation"
      />
    </Form.Item>
  )
}
