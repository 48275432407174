import { Form, Input, message, Radio } from "antd"
import { RuleObject } from "antd/lib/form"
import debounce from "debounce-promise"
import { useState } from "react"
import { userAssignableRoles, formatOrgRole, getOrgRole } from "src/organizations/roles"
import { DEBOUNCE_DELAY, MESSAGE_DURATION_SECONDS } from "src/common/config"
import { Button } from "src/ui/Button/Button"
import { ButtonWrapper } from "src/users/Users.styles"
import { emailRegex } from "src/common/utils/emailValidationRegex"
import { StyledRadioGroup } from "src/users/UserModal.styles"
import { StyledFormItem } from "src/ui/Form/FormItem"
import { FormModal } from "src/ui/AppModal/AppModal"
import { RoleTooltip } from "src/common/tooltips/RoleTooltip"
import {
  InviteUserToOrganizationDocument,
  UsersDocument,
  InviteUserToOrganizationMutation,
  UsersQuery,
} from "src/api/graphql/graphql-operations"
import { ApolloCache, FetchResult, useMutation, useQuery } from "@apollo/client"
import { StyledTooltipIcon } from "src/ui/Tooltip/Tooltip"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { useTheme } from "styled-components"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"
const roles = userAssignableRoles.map((name) => formatOrgRole(name))

const InviteUserModal = () => {
  const theme = useTheme()
  const { data, loading: qLoading, error: qError } = useQuery(UsersDocument)

  let emails = data?.allUsers?.edges.map((e) => e?.node?.email ?? "") ?? []

  // effectively disable client-side validation if there is an issue
  if (qLoading || qError) {
    emails = []
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [form] = Form.useForm()

  const validateFormOnValueChanges = (isEmailInvalid?: boolean) => {
    const someValuesAreNotChanged = !form.isFieldsTouched(true)
    const isEmailInvalidOrErrorsInForm = isEmailInvalid ?? form.getFieldsError().some(({ errors }) => errors.length)
    setIsButtonDisabled(someValuesAreNotChanged || isEmailInvalidOrErrorsInForm)
  }

  const [iniviteUserToOrganization, { loading, error, reset: resetMutation }] = useMutation(
    InviteUserToOrganizationDocument,
    {
      update: updateCacheToAddUser,
      onCompleted: () => {
        reset()
        message.success("Invitation sent", MESSAGE_DURATION_SECONDS)
      },
    },
  )

  const reset = () => {
    setIsModalOpen(false)
    setIsButtonDisabled(true)
    form.resetFields()
    resetMutation()
  }
  const onFinish = () => {
    if (loading || error) return
    const { email, role } = form.getFieldsValue()
    const organizationRole = getOrgRole(role)
    if (organizationRole) {
      iniviteUserToOrganization({
        variables: {
          input: {
            email,
            organizationRole: organizationRole,
          },
        },
      })
    } else {
      throw new Error("Invalid organization role") // This is unlikely but in case we add new roles or change the enum this will come handly
    }
  }

  const changeEmailValidity = debounce((value: string) => {
    const isEmailInvalid = value.toLowerCase().match(emailRegex)
    if (!isEmailInvalid) {
      validateFormOnValueChanges(true)
      return "invalid"
    }
    const userAlreadyExist = emails.find((email) => email === value)

    validateFormOnValueChanges(userAlreadyExist ? true : false)
    return Promise.resolve(userAlreadyExist ? "alreadyExist" : "valid")
  }, DEBOUNCE_DELAY)

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        Invite User
      </Button>
      <FormModal
        open={isModalOpen}
        onCancel={reset}
        title="Invite User"
        footer={
          <ButtonWrapper>
            <Button aria-label="Cancel" onClick={reset}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: theme.spacing.horizontal.xs }}
              type="primary"
              danger={!!error}
              onClick={onFinish}
              disabled={isButtonDisabled}
              loading={loading}
            >
              Invite
            </Button>
          </ButtonWrapper>
        }
      >
        <Form
          form={form}
          name="inviteUser"
          onValuesChange={({ role }) => {
            if (role) {
              validateFormOnValueChanges()
            }
          }}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            hasFeedback
            required
            rules={[
              () => {
                return {
                  async validator(_: RuleObject, value: string) {
                    const validateFormOnValueChanges = (isEmailInvalid: boolean) => {
                      setIsButtonDisabled(!form.isFieldsTouched(true) || isEmailInvalid)
                    }

                    if (!value) {
                      validateFormOnValueChanges(true)
                      return Promise.reject("Enter an email address")
                    }
                    const response = await changeEmailValidity(value)
                    if (response === "invalid") return Promise.reject("Invalid email address")
                    if (response === "alreadyExist")
                      return Promise.reject(new Error("This user has already been invited"))
                    if (response === "valid") return Promise.resolve()
                  },
                }
              },
            ]}
          >
            <Input placeholder="Email address" type="email" aria-label="email address input" />
          </Form.Item>
          <StyledFormItem
            label="Organization Role"
            name="role"
            required
            tooltip={{
              icon: <StyledTooltipIcon name="questionCircle" width="18px" />,
              title: RoleTooltip,
            }}
          >
            <StyledRadioGroup>
              {roles.map((role) => (
                <Radio key={role} value={role} aria-label={`${role} checkbox`}>
                  {role}
                </Radio>
              ))}
            </StyledRadioGroup>
          </StyledFormItem>
        </Form>
        {error && (
          <AlertBanner
            description={
              <>
                There was a problem inviting this user. If the issue persists, contact <SupportEmailLink />
              </>
            }
            message="Failed to invite user"
            type="error"
            showIcon
          />
        )}
      </FormModal>
    </>
  )
}

export { InviteUserModal }

function updateCacheToAddUser(cache: ApolloCache<unknown>, result: FetchResult<InviteUserToOrganizationMutation>) {
  cache.updateQuery({ query: UsersDocument }, (data: UsersQuery | null) => {
    const user = result?.data?.inviteUserToOrganization
    if (!user || data?.allUsers === null || data?.allUsers === undefined) {
      return null
    }
    const newEdge = { __typename: "UserEdge", node: user } as const
    return {
      ...data,
      allUsers: {
        ...data.allUsers,
        edges: [...data.allUsers.edges, newEdge],
      },
    }
  })
}
