import { JsonForm } from "src/DataAssets/connect-to-data/JsonForm"
import { JsonFormsCore } from "@jsonforms/core"
import {
  ValueSetUISchemaRegistryEntry,
  useUISchemaFromJSONSchema,
} from "src/Expectation/uiForms/schemas/expectation-catalog-ui-schemas"
import { OSSMetaNotesRegistryEntry } from "src/Expectation/uiForms/customRenderers/MetaNotesControl"
import { ColumnNameRegistryEntry } from "src/Expectation/uiForms/customRenderers/ColumnNameControl"
import { JSONSchema } from "json-schema-to-ts"
import { ExpectationFormData } from "src/schemas/expectation-catalog-types"
import { MinMaxRegistryEntry } from "src/Expectation/uiForms/customRenderers/MinMaxControl"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { getJsonSchemaWithCDMValues, handleChangeWithCDMValues } from "src/Expectation/uiForms/cdmUtils"
import { DataAssetWithLatestMetricsRunQuery } from "src/api/graphql/graphql"
import { SQLEditorRegistryEntry } from "src/Expectation/uiForms/customRenderers/SQLEditorControl.tsx"
import {
  WindowedRangeWithOffsetUISchemaRegistry,
  WindowedFixedValuesUISchemaRegistry,
  WindowedRangeWithFixedOffsetUISchemaRegistry,
  WindowedFixedValuesStrictUISchemaRegistry,
  PlaintextRendererUISchemaRegistry as PlaintextLabelRegistryEntry,
} from "src/Expectation/uiForms/windowUtils"
import { LabelRendererRegistryEntry } from "src/jsonforms/controls/AlertControl"
import { SupportedExpectation } from "src/schemas/expectation-metadata-utils"
import { GroupRendererRegistryEntry } from "src/jsonforms/layouts/GroupRenderer"
import { RowConditionRegistryEntry } from "src/Expectation/uiForms/customRenderers/RowConditionControl/RowConditionControl"
import { handleBaselineConfig as handleBaseWindowConfig } from "src/Expectation/CreateExpectationDrawer/windowedExpectationUtils"

export type ExpectationJsonSchema = {
  [key in SupportedExpectation]: (typeof jsonSchemas)[key]["schema"]
}[SupportedExpectation]

export type DataAssetWithLatestMetricRunConfig = {
  dataAssetWithLatestMetricRun: DataAssetWithLatestMetricsRunQuery["dataAsset"]
}
export interface ExpectationConfigFormProps {
  jsonSchema: ExpectationJsonSchema | JSONSchema
  value?: Record<string, unknown>
  onChange: (value: Pick<JsonFormsCore, "data" | "errors">) => void
  config?: DataAssetWithLatestMetricRunConfig
}

export const ExpectationConfigForm = ({ jsonSchema, value, onChange, config }: ExpectationConfigFormProps) => {
  const configuredJsonSchema = getJsonSchemaWithCDMValues(jsonSchema as ExpectationJsonSchema, config)
  const expectationClass =
    typeof jsonSchema !== "boolean" &&
    jsonSchema.properties?.metadata !== undefined &&
    typeof jsonSchema.properties?.metadata !== "boolean" &&
    typeof jsonSchema.properties?.metadata.properties?.expectation_class !== "boolean" &&
    typeof jsonSchema.properties?.metadata.properties?.expectation_class.const === "string"
      ? jsonSchema.properties?.metadata.properties?.expectation_class.const
      : ""
  const uiSchema = useUISchemaFromJSONSchema(expectationClass)

  const handleChange = (newValue?: ExpectationFormData) => {
    const dataWithCDMValues = handleChangeWithCDMValues(expectationClass, value, newValue, config)
    onChange({ data: dataWithCDMValues })
  }

  const patchedValue = handleBaseWindowConfig(value)

  return (
    <JsonForm
      jsonSchema={configuredJsonSchema as JSONSchema}
      uiSchema={uiSchema}
      data={patchedValue as Record<string, unknown>}
      updateData={handleChange}
      customRendererRegistryEntries={[
        OSSMetaNotesRegistryEntry,
        ColumnNameRegistryEntry,
        MinMaxRegistryEntry,
        SQLEditorRegistryEntry,
        LabelRendererRegistryEntry,
        PlaintextLabelRegistryEntry,
        GroupRendererRegistryEntry,
        RowConditionRegistryEntry,
      ]}
      uiSchemaRegistryEntries={[
        ValueSetUISchemaRegistryEntry,
        WindowedFixedValuesUISchemaRegistry,
        WindowedRangeWithOffsetUISchemaRegistry,
        WindowedFixedValuesStrictUISchemaRegistry,
        WindowedRangeWithFixedOffsetUISchemaRegistry,
      ]}
      config={{ ...config }}
    />
  )
}
