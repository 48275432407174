interface EmojiProps {
  label: string
  symbol: number
}

export const Emoji = ({ label, symbol }: EmojiProps) => (
  <span role="img" aria-label={label}>
    {String.fromCodePoint(symbol)}
  </span>
)
