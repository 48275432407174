import { useState } from "react"
import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import { Col, Row, Card, Descriptions, Space, message } from "antd"

import { graphql } from "src/api/graphql/gql"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MetricsTable } from "src/DataAssets/AssetDetails/MetricsTable"
import { SimpleNewExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer"
import { useAssetSummaryDescriptionItems } from "src/DataAssets/AssetDetails/useAssetSummaryDescriptionItems"
import { CreateExpectationForAssetDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetDrawer"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { MainContent } from "src/layout/MainContent"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { Heading3 } from "src/ui/typography/Text/Text"
import { useIsDemoData } from "src/common/hooks/useIsDemoData"
import { DataAssetOverviewAlert } from "src/Alerts/DemoData/DataAssetOverviewAlert"
import { useDemoDataAssetName } from "src/common/hooks/useDemoDataAssetName"
import { MESSAGE_DURATION_SECONDS } from "src/common/config.ts"
import { useDataSourceName } from "src/DataAssets/AssetDetails/useDataSourceName"

export const OverviewTab_DataAssetDocument = graphql(`
  query OverviewTab_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
      }
      splitter {
        ... on WithID {
          id
        }
      }
      ...AssetSummaryDescriptionItems_DataAsset
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
      ...UseDataSourceName_DataAsset
    }
  }
`)

const CreateRunRdAgentJobDocument = graphql(`
  mutation CreateRunRdAgentJob($input: CreateRunRdAgentJobInput!) {
    createRunRdAgentJob(input: $input) {
      jobId
    }
  }
`)

export function OverviewTab() {
  const { assetId } = useParams<Record<"assetId", string>>()
  const dataAssetId = decodeURIComponent(assetId ?? "")

  const { data } = useQuery(OverviewTab_DataAssetDocument, {
    variables: { id: dataAssetId },
    skip: !dataAssetId,
  })
  const [isAssistantRequested, setIsAssistantRequested] = useState(false)

  const [runRdAgentJob, { loading: runRdAgentMutationLoading }] = useMutation(CreateRunRdAgentJobDocument, {
    onCompleted: () => {
      close()
      setIsAssistantRequested(true)
      message.success("Successfully requested agent run.", MESSAGE_DURATION_SECONDS)
    },
    onError: () => {
      message.error("Failed to request agent run.", MESSAGE_DURATION_SECONDS)
    },
  })

  const isEditor = useRequireRole("EDITOR")
  const isSujeEnabled = useIsFeatureEnabled("sujeEnabled")
  const columnChangeDetectionEnabled = useIsFeatureEnabled("columnChangeDetection")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const doRequestAssistant = async (batchDefinitionId: string | undefined) => {
    if (!batchDefinitionId) {
      return
    }
    await runRdAgentJob({ variables: { input: { batchDefinitionId: batchDefinitionId } } })
  }

  const agentRequestStatus = () => {
    if (runRdAgentMutationLoading) {
      return "Requesting review..."
    }
    if (isAssistantRequested) {
      return "Review in progress"
    }
    return "Request review"
  }

  const rdAssistantEnabled = useIsFeatureEnabled("rdAssistantEnabled")

  const isDemoData = useIsDemoData(data?.dataAsset)
  const assetName = useDemoDataAssetName(data?.dataAsset)

  const headerContent: HeaderTypes = {
    title: data?.dataAsset?.name ?? "",
    rootPath: "data-assets",
    navigateBackTo: "/data-assets",
    ...(isEditor && {
      rightActions: {
        ...(rdAssistantEnabled && {
          secondaryButton: {
            type: "primary",
            icon: "refresh",
            loading: runRdAgentMutationLoading,
            disabled: isAssistantRequested || runRdAgentMutationLoading,
            children: agentRequestStatus(),
            onClick: () => doRequestAssistant(data?.dataAsset?.splitter?.id),
          },
        }),
        ctaButton: {
          type: "primary",
          icon: "plus",
          children: "New Expectation",
          disabled: !dataAssetId,
          onClick: () => setIsDrawerOpen(true),
        },
      },
    }),
    customBreadcrumbs: getDataAssetBreadcrumbs({
      dataSource: data?.dataAsset?.datasourceV2,
      tooltipPlacement: "right",
    }),
    footer: <DataAssetTabs currentTab={isSujeEnabled && columnChangeDetectionEnabled ? "metrics" : "overview"} />,
  }
  const descriptionItems = useAssetSummaryDescriptionItems(data?.dataAsset)
  const dataSourceName = useDataSourceName(data?.dataAsset)

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {dataAssetId && !isSujeEnabled && (
          <CreateExpectationDrawerContextProvider open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <CreateExpectationForAssetDrawer
              assetId={dataAssetId}
              assetName={data?.dataAsset?.name}
              dataSourceName={dataSourceName}
            />
          </CreateExpectationDrawerContextProvider>
        )}
        {dataAssetId && isSujeEnabled && (
          <SimpleNewExpectationDrawer
            dataAssetId={dataAssetId}
            open={isDrawerOpen}
            close={() => setIsDrawerOpen(false)}
          />
        )}
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            <Card title={<Heading3>Data Asset Information</Heading3>}>
              <Descriptions column={1} layout="vertical" items={descriptionItems} />
            </Card>
          </Col>
          <Col xs={24} xl={18} className="sentry-mask">
            <Space direction="vertical" size="large" style={{ display: "flex" }}>
              {isDemoData && <DataAssetOverviewAlert assetName={assetName} />}
              {dataAssetId && <MetricsTable assetId={dataAssetId} />}
            </Space>
          </Col>
        </Row>
      </MainContent>
    </PageHeader>
  )
}
